/**
 * GraphQL queries and React Query hooks for fetching Contentful data.
 */
import {
    GET_ACTIVATEPAGE_CONTENT_QUERY, GET_CONFIRM_MOBILE_PAGE_CONTENT_QUERY, GET_CONFIRM_YOUR_DETAILS_CONTENT_QUERY,
    GET_FORGOTPASSWORD_CONTENT_QUERY, GET_LOGINPAGE_CONTENT_QUERY, GET_NO_CLAIMDETAILS_CONTENT_QUERY,
    GET_RESETPASSWORD_CONTENT_QUERY, GET_SIGNUPPAGE_CONTENT_QUERY, GET_TRAYMENU_ITEMS_CONTENT_QUERY, GET_TWOFACTORPAGE_CONTENT_QUERY,
    GET_YOUR_DECLARATION_PAGE_CONTENT_QUERY, GET_YOUR_DETAILS_PAGE_CONTENT_QUERY
} from './../../graphql/queries/graphql-contentful-queries';
import { GET_SUPERFUND_DATA_QUERY } from './../../graphql/queries/graphql-queries.constant';
import { useQuery } from '@tanstack/react-query';
import { API, THEME_CODE } from "../../constants/constants";
import axios from "axios";
import {
    GET_CLAIMDETAILS_CONTENT_QUERY, 
    GET_ERROR_PAGES_CONTENT_QUERY, 
    GET_FOOTER_PAGE_CONTENT_QUERY, 
    GET_FUND_BANNER_DETAILS_CONTENT_QUERY,
    GET_HEALTH_AND_SUPPORT_PAGE_CONTENT_QUERY,
    GET_HOMEPAGE_CONTENT_QUERY, 
    GET_MESSAGEHUB_DETAILS_CONTENT_QUERY,
    GET_NEED_HELP_PAGE_CONTENT_QUERY, 
    GET_STATUS_DESCRIPTION_PAGE_CONTENT_QUERY
} from '../../graphql/queries/graphql-contentful-queries';
import { useCookies } from 'react-cookie';

/**
 * Cache expiration time (5 minutes).
 */
const STALE_TIME = 1000 * 60 * 10;
const GC_TIME = 1000 * 60 * 15

/**
 * Fetches GraphQL data from a given API URL.
 *
 * @param {string} query - The GraphQL query string.
 * @param {string} [fundName] - Optional fund name variable for queries that require it.
 * @param {boolean} [isContentful=true] - Indicates if the query is for Contentful data.
 * @returns {Promise<any>} - The response data from the API.
 */
const fetchGraphQLData = async (
    query: string,
    fundName?: string,
    isContentful: boolean = true,
): Promise<any> => {
    try {
        const apiURL = isContentful ? API.CONTENTFUL_URL : API.BASEURL
        const variables = fundName ? { fundName } : {};

        const response = await axios.post(apiURL, {
            query,
            variables
        });

        if (!response.data?.data) {
            console.error("Invalid API response format")
            return Promise.reject("Invalid API response")
        }

        return isContentful ? response.data.data.items.items[0] : response.data.data
    } catch (error: any) {
        console.error("Error in fetchGraphQLData:", error);
        return Promise.reject(`Error in fetchGraphQLData: ${error}`)
    }
};

/**
 * Creates a React Query hook for fetching data.
 *
 * @param {string} queryKey - The unique key for caching the query.
 * @param {string} query - The GraphQL query string.
 * @param {boolean} [isContentful=true] - Indicates if the query is for Contentful data.
 * @returns {() => UseQueryResult<any, any>} - A React hook function that returns the query result.
 */
const getFundSpecificContentQuery = (queryKey: string, query: string, isContentful: boolean = true, enabled = true) => {
    return () => {
        const [{ tpid }] = useCookies(["tpid"]);

        return useQuery({
            queryKey: [queryKey],
            queryFn: () => fetchGraphQLData(query, tpid, isContentful),
            enabled,
            staleTime: STALE_TIME,
            gcTime: GC_TIME,
        });
    };
};

/**
 * Creates a React Query hook for fetching content that is not specific to a fund.
 * This is useful for content that is shared across all funds or for data that
 * doesn't require a fund context.
 *
 * @param {string} queryKey - The unique key for caching the query.  Should be an array of strings.
 * @param {string} query - The GraphQL query string.
 * @param {boolean} [isContentful=true] - Indicates if the query is for Contentful data.
 * @param {boolean} [enabled=true] - Controls whether the query is initially enabled.
 * @returns {() => UseQueryResult<any, any>} - A React hook function that returns the query result.
 */
const getFundAgnosticContentQuery = (
    queryKey: string,
    query: string,
    isContentful: boolean = true,
    enabled: boolean = true
) => {
    return () => {
        return useQuery({
            queryKey: [queryKey],
            queryFn: () => fetchGraphQLData(query, "", isContentful),
            enabled,
            staleTime: STALE_TIME,
            gcTime: GC_TIME,
        });
    };
};


/**
 * Creates a React Query hook for fetching footer and header data.
 * Uses TPID if available, otherwise falls back to a default theme code.
 *
 * @param {string} queryKey - The unique key for caching the query.
 * @param {string} query - The GraphQL query string.
 * @returns {() => UseQueryResult<any, any>} - A React hook function that returns the query result.
 */
const getContentQueryForFooterAndHeader = (queryKey: string, query: string, enabled = true) => {
    return () => {
        const [{ tpid }] = useCookies(["tpid"]);
        const fundArgument = tpid ?? THEME_CODE["TAL"];

        return useQuery({
            queryKey: [queryKey],
            queryFn: () => fetchGraphQLData(query, fundArgument),
            enabled,
            staleTime: STALE_TIME,
            gcTime: GC_TIME,
        });
    };
};

// Hooks for fetching Contentful data with fund name.
export const useGetStatusDescriptionPageContent = getFundSpecificContentQuery("statusDescriptionPageContent", GET_STATUS_DESCRIPTION_PAGE_CONTENT_QUERY);
export const useGetHomePageContent = getFundSpecificContentQuery("homePageContent", GET_HOMEPAGE_CONTENT_QUERY);
export const useGetClaimDetailsContent = getFundSpecificContentQuery("claimDetailsContent", GET_CLAIMDETAILS_CONTENT_QUERY);
export const useGetMessageHubDetailsContent = getFundSpecificContentQuery("messageHubDetailsContent", GET_MESSAGEHUB_DETAILS_CONTENT_QUERY);
export const useGetHealthAndSupportPageContent = getFundSpecificContentQuery("healthAndSupportPageContent", GET_HEALTH_AND_SUPPORT_PAGE_CONTENT_QUERY);
export const useGetNeedHelpPageContent = getFundSpecificContentQuery("needHelpPageContent", GET_NEED_HELP_PAGE_CONTENT_QUERY);
export const useGetErrorPagesContent = getFundSpecificContentQuery("errorPagesContent", GET_ERROR_PAGES_CONTENT_QUERY);
export const useGetYourDetailsPageContent = getFundSpecificContentQuery("yourDetailsPageContent", GET_YOUR_DETAILS_PAGE_CONTENT_QUERY)
export const useGetYourDeclarationPageContent = getFundSpecificContentQuery("yourDeclarationPageContent", GET_YOUR_DECLARATION_PAGE_CONTENT_QUERY)
export const useGetNoClaimDetailsPageContent = getFundSpecificContentQuery("noClaimDetailsPageContent", GET_NO_CLAIMDETAILS_CONTENT_QUERY)
export const useGetTrayMenuItemsContent = getFundSpecificContentQuery("trayMenuItemsContent", GET_TRAYMENU_ITEMS_CONTENT_QUERY)

// Hooks for fetching Contentful data without fund name.
export const useGetSuperfundData = getFundAgnosticContentQuery("superfundData", GET_SUPERFUND_DATA_QUERY, false);
export const useGetLoginPageContentData = getFundAgnosticContentQuery("loginPageContent", GET_LOGINPAGE_CONTENT_QUERY);
export const useGetSignUpPageContentData = getFundAgnosticContentQuery("signUpPageContent", GET_SIGNUPPAGE_CONTENT_QUERY)
export const useGetTwoFactorPageContentData = getFundAgnosticContentQuery("twoFactorPageContent", GET_TWOFACTORPAGE_CONTENT_QUERY)
export const useGetResetPasswordContentData = getFundAgnosticContentQuery("resetPasswordPageContent", GET_RESETPASSWORD_CONTENT_QUERY)
export const useGetForgotPasswordContentData = getFundAgnosticContentQuery("forgotPasswordPageContent", GET_FORGOTPASSWORD_CONTENT_QUERY)
export const useGetConfirmMobilePageContentData = getFundAgnosticContentQuery("confirmMobilePageContent", GET_CONFIRM_MOBILE_PAGE_CONTENT_QUERY)
export const useGetActivatePageContentData = getFundAgnosticContentQuery("activatePageContent", GET_ACTIVATEPAGE_CONTENT_QUERY)
export const useGetConfirmYourDetailsPageContent = getFundAgnosticContentQuery("confirmYourDetailsPageContent", GET_CONFIRM_YOUR_DETAILS_CONTENT_QUERY)

// Hooks for fetching footer and header Contentful data.
// refactor this function, because it's called 2-3 times over and over again, I need to get rid of the redundant calls
export const useGetFundBannerDetailsContent = getContentQueryForFooterAndHeader("bannerDetailsContent", GET_FUND_BANNER_DETAILS_CONTENT_QUERY);
export const useGetFooterPageContent = getContentQueryForFooterAndHeader("footerPageContent", GET_FOOTER_PAGE_CONTENT_QUERY);
